<template>
  <!-- <van-overlay  v-model="show" class="tipsview" @click="clickWarp">
    <div class="tips" @click.stop>
      <img src="" alt="" /><img src="../../assets/erweima.jpg" width="100%" alt="" />
      <p>扫一扫注册小程序</p>
    </div>
  </van-overlay> -->
  <van-overlay :show="show" @click="clickWarp">
    <div class="tips" @click.stop>
      <img src="" alt="" /><img
        src="../../assets/erweima.jpg"
        width="100%"
        alt=""
      />
      <p>扫一扫注册小程序</p>
    </div>
  </van-overlay>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    clickWarp() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style scoped>
/* .tipsview{width:50%;border-radius: 5px;} */
.tips {
  background:#fff;padding:10px;border-radius: 10px;
  text-align: center;
  width:50%;margin:40% auto;
}
.tips p {
  margin: 5px 0;
}
</style>