<template>
  <div class="wrap">
    <!-- <van-nav-bar title="个人中心" /> -->
    <!-- 登录按钮 -->
    <div class="login">
      <van-button type="primary" @click="showTips=true" text="登录/注册"></van-button>
    </div>
    <!-- 业务 -->
    <div class="column">
        <van-cell is-link @click="showTips=true">网上办事</van-cell>
        <van-cell is-link @click="showTips=true">网上预约</van-cell>
        <van-cell is-link @click="showTips=true">建言献策</van-cell>
        <van-cell is-link to="/activityLog">我的活动</van-cell>
    </div>
    <TipsMiniProject :show.sync="showTips" />
    <!-- 底部按钮 -->
    <tabbar :active="2" />
    <TipsMiniProject :show="false" />
  </div>
  
</template>

<script>
import TipsMiniProject from "@/components/TipsMiniProject"
import Tabbar from "@/components/Tabbar"
export default {
  components : {
    Tabbar, TipsMiniProject
  },
  data() {
    return {
      active: 2,
      showTips: false,
    }
  }
}
</script>

<style>
.column {
  margin: 0 auto;
  border-radius: 10px;
  padding: 10px 10px;
}
.login {
  background: #337ab7;
  width: 100%;
  height: 100px;
}
.login button {
  top: 50%;
  left: 50%;
  margin-left: -115px;
  margin-top: -20px;
  width: 230px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #337ab7;
  background: #fff;
  color: black;
  font-weight: 700;
  font-size: 18px;
}
</style>