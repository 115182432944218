<template>
  <!-- 底部按钮 -->
  <van-tabbar v-model="active">
    <van-tabbar-item icon="wap-home" to="/">首页</van-tabbar-item>
    <van-tabbar-item icon="chat" to="/serve">社情民意</van-tabbar-item>
    <van-tabbar-item icon="wap-home-o" to="/village">我的社区</van-tabbar-item>
    <van-tabbar-item icon="friends" to="/center">我的</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  props: {
    active: {
      required: true,
      default: undefined,
    },
  },
};
</script>

<style>
</style>